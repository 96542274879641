/* BASE - Base Variable file along with starting point Mixins and Placeholders.
========================================================================== */
/* OPACITY LAYER WHICH LOOKS DISABLED
=================================================================== */
html, body {
  height: 100%;
  width: 100%;
  color: #929292;
  background-color: #f8f8f8;
  line-height: 160%;
  font-weight: 200;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

*, *:after, *:before {
  box-sizing: border-box;
}

@font-face {
  font-family: "German Beauty";
  src: url("../fonts/GermanBeauty.eot?#iefix") format("embedded-opentype"), url("../fonts/GermanBeauty.woff") format("woff"), url("../fonts/GermanBeauty.woff2") format("woff2"), url("../fonts/GermanBeauty.ttf") format("truetype");
}

@font-face {
  font-family: "Cabin Sketch Bold";
  src: url("../fonts/CabinSketchBold.eot?#iefix") format("embedded-opentype"), url("../fonts/CabinSketchBold.woff") format("woff"), url("../fonts/CabinSketchBold.woff2") format("woff2"), url("../fonts/CabinSketchBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lobster";
  src: url("../fonts/Lobster.eot?#iefix") format("embedded-opentype"), url("../fonts/Lobster.woff") format("woff"), url("../fonts/Lobster.woff2") format("woff2"), url("../fonts/Lobster.ttf") format("truetype");
}

@font-face {
  font-family: "Luckiest Guy";
  src: url("../fonts/LuckiestGuy.eot?#iefix") format("embedded-opentype"), url("../fonts/LuckiestGuy.woff") format("woff"), url("../fonts/LuckiestGuy.woff2") format("woff2"), url("../fonts/LuckiestGuy.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans.woff") format("woff"), url("../fonts/OpenSans.woff2") format("woff2"), url("../fonts/OpenSans.ttf") format("truetype");
}

@font-face {
  font-family: "Architects Daughter";
  src: url("../fonts/ArchitectsDaughter.eot?#iefix") format("embedded-opentype"), url("../fonts/ArchitectsDaughter.woff") format("woff"), url("../fonts/ArchitectsDaughter.woff2") format("woff2"), url("../fonts/ArchitectsDaughter.ttf") format("truetype");
}

@font-face {
  font-family: "Source Code Pro";
  src: url("../fonts/SourceCodePro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceCodePro-Regular.woff") format("woff"), url("../fonts/SourceCodePro-Regular.woff2") format("woff2"), url("../fonts/SourceCodePro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lucida Sans Unicode";
  src: url("../fonts/LucidaSansUnicode.eot?#iefix") format("embedded-opentype"), url("../fonts/LucidaSansUnicode.woff") format("woff"), url("../fonts/LucidaSansUnicode.woff2") format("woff2"), url("../fonts/LucidaSansUnicode.ttf") format("truetype");
}

img {
  width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, .font-primary {
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
}

.main-container {
  position: relative;
}

.small-screen {
  display: none;
}

@media (max-width: 991px) {
  .small-screen {
    display: block;
    padding: 10vh 10vw;
    text-align: center;
  }
  .small-screen h1 {
    color: #00cc99;
  }
  .small-screen img {
    margin-top: 50px;
  }
}

.full-screen {
  max-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  margin: 0;
  position: relative;
}

.row-no-padding > [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.container-max-height {
  height: 100%;
}

.container-max-height .row,
.container-max-height .row-fluid {
  height: 100%;
}

.container-max-height [class*="col-"] {
  height: 100%;
}

.flex-container {
  display: -webkit-box;
  display: flex;
}

.pointer {
  cursor: pointer;
}

.font-logo {
  font-family: "German Beauty", sans-serif;
}

/* FRAMEWORK - Structure and layout files.
========================================================================== */
/* Home Page
=================================================================== */
.unit-title {
  text-align: center;
  font-size: 300%;
  color: #929292;
}

/* Hero Unit
=================================================================== */
.hero-unit {
  min-height: calc(100vh - 55px);
  position: relative;
  padding: 0;
  background: #9fafc9 url("/assets/img/hero-unit-mobile.png") no-repeat left bottom;
  background-size: 100% auto;
}

@media (min-width: 768px) {
  .hero-unit {
    background: #9fafc9 url("/assets/img/hero-unit.png") no-repeat left bottom;
    background-size: 100% auto;
  }
}

.hero-title {
  padding: 0 30px;
  color: #fff;
  font-size: 330%;
  text-align: center;
  font-family: "Architects Daughter", sans-serif;
}

.hero-subtitle {
  font-family: "Architects Daughter", sans-serif;
  font-size: 200%;
  color: #fff;
}

.btn-hero {
  margin: 10px auto;
  display: block;
  background-color: #fff;
  color: #9fafc9;
  border-color: #fff;
  font-size: 150%;
  border-radius: 15px;
  width: 200px;
  text-transform: uppercase;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
}

.btn-hero:hover {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}

.hero-title {
  margin: 80px 0 50px;
}

@media (min-width: 768px) {
  .hero-logo {
    width: 350px;
    display: block;
    margin: 50px auto 30px;
  }
  .hero-title {
    margin: 0 0 80px;
  }
  .hero-button-wrapper {
    display: block;
    width: 460px;
    margin: 0 auto;
  }
  .btn-hero {
    margin: 0 15px;
    display: block;
    float: left;
  }
}

/**
* iPad with portrait orientation.
*/
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .hero-unit {
    min-height: 969px;
  }
}

/**
 * iPad with landscape orientation.
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  .hero-unit {
    min-height: 768px;
  }
}

/**
 * iPhone 5
 */
@media screen and (device-aspect-ratio: 40 / 71) {
  .hero-unit {
    min-height: 500px;
  }
}

/* BENEFITS UNIT
=================================================================== */
.benefits-unit {
  background-color: #f8f8f8;
  color: #929292;
  text-align: center;
  font-size: 18px;
  font-weight: 200;
  padding: 50px 0;
}

.benefits-unit .benefit-wrapper {
  margin-top: 20px;
}

.benefits-unit .benefit img {
  height: auto;
  width: 100%;
  max-width: 100%;
  margin: 10px auto;
  -webkit-transition: -webkit-filter 0.1s;
  transition: -webkit-filter 0.1s;
  transition: filter 0.1s;
  transition: filter 0.1s, -webkit-filter 0.1s;
}

.benefits-unit .benefit img:hover {
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.25));
}

.benefits-unit .benefit-title {
  margin: 0 0 15px;
  color: #00cc99;
}

.benefits-unit .benefit-info {
  padding: 0 10px;
}

.benefits-unit video {
  margin: 40px auto;
  display: block;
  width: 600px;
  max-width: calc(100vw - 60px);
  height: auto;
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.25));
}

@media (min-width: 992px) {
  .benefit-info {
    padding: 5px 25px;
  }
}

/* Parallax Unit
=================================================================== */
.parallax {
  min-height: 400px;
  background: #9fafc9 url("/assets/img/hero-unit.png") no-repeat left bottom;
  background-size: cover;
}

/*
@media (min-width: $screen-sm-min) {
  .parallax {
    //background-image: url('/assets/img/hero-unit.png');
    //background-position: center center;
    //background-repeat: no-repeat;
    //background-attachment: fixed !important;
    //background-size: cover !important;
  }
}
*/
/* Pricing Plans
=================================================================== */
.plans-title {
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  font-size: 300%;
  text-align: center;
  margin-top: 20px;
}

.plans-title span {
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  color: #00cc99;
}

@media (min-width: 768px) {
  .plans-title {
    margin: 30px 0 70px;
  }
}

.plan-box {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 1px solid #eee;
  text-align: center;
  z-index: 1;
  border-top: 5px solid #00cc99;
  border-bottom: 2px solid #00cc99;
  position: relative;
  margin: 30px auto;
  width: calc(100% - 30px);
}

@media (min-width: 768px) {
  .plan-box {
    margin: 0 auto;
    width: calc(100% + 10px);
    left: -5px;
  }
}

.plan-recommended {
  color: #fff;
  background-color: #cc0066;
  border: 1px solid #cc0066;
  height: 30px;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  text-transform: uppercase;
}

.plan-box-header {
  background-color: #f8f8f8;
  color: #00cc99;
  padding: 15px 0 10px;
  border-bottom: 1px solid #eee;
  border-radius: 10px 10px 0 0;
}

.plan-title {
  margin: 0;
}

.plan-box-image {
  background-color: #fff;
  height: 150px;
  padding: 15px 0;
}

.plan-box-image img {
  height: 100%;
  width: auto;
}

.plan-box-pricing {
  padding: 15px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.plan-box-pricing .plan-price {
  margin: 0;
}

.plan-box-pricing .plan-price-terms {
  margin: 12px 0 0;
  font-family: sans-serif;
  color: #c5c5c5;
  font-weight: 100;
  font-size: 16px;
  position: relative;
  top: -2px;
}

.plan-box-features {
  padding: 15px 30px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.plan-box-features i {
  color: #00cc99;
  font-size: 18px;
}

.plan-box-features .not-available i {
  color: #ff464b;
  margin: 0 2px;
  font-size: 18px;
}

.plan-box-button {
  padding: 15px 15px;
}

.plan-box-button .btn {
  width: 100%;
  border-radius: 5px;
}

.plan-box.main {
  margin-top: 30px;
  border-top: 5px solid #cc0066;
  border-bottom: 2px solid #cc0066;
}

.plan-box.main .plan-box-header {
  color: #cc0066;
}

@media (min-width: 768px) {
  .plan-box.main {
    top: -30px;
    margin-top: 0;
  }
}

.plan-box.school {
  border-top: 5px solid #009999;
  border-bottom: 2px solid #009999;
}

.plan-box.school .plan-box-header {
  color: #009999;
}

.plan-box.school .plan-box-button .btn-primary {
  background-color: #f8f8f8;
  color: #009999 !important;
  border-color: #009999;
}

.join-profile-pic {
  border-radius: 50%;
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.join-username {
  font-family: "Architects Daughter", sans-serif;
  font-size: 240%;
  margin-top: 30px;
  text-align: center;
}

.join-testimonial {
  font-family: "Architects Daughter", sans-serif;
}

/* Onboarding Unit
=================================================================== */
.onboarding-unit {
  height: calc(100vh - 55px);
  padding-top: 100px;
  position: relative;
}

.editor-wrapper.stand-alone {
  height: 350px;
  width: 100%;
  display: block;
}

.editor-wrapper.stand-alone .cm-s-youplusplus {
  font-size: 18px;
  border-top: 30px solid #eee;
  border-bottom: 30px solid #eee;
  border-radius: 20px;
  box-shadow: 0 0 30px 2px #929292;
}

.editor-wrapper.stand-alone .cm-s-youplusplus .CodeMirror-gutters {
  background-color: #fff;
}

.editor-wrapper.stand-alone .cm-s-youplusplus .CodeMirror-linenumber {
  color: #00cc99;
}

.canvas.stand-alone {
  width: 275px;
  height: 275px;
  margin: 0 auto;
  display: block;
}

.canvas.stand-alone #processing-canvas {
  border-radius: 50%;
  box-shadow: 0 0 30px 2px #929292;
}

.btn-blank-wrapper.stand-alone {
  display: block;
  float: right;
  padding-top: 30px;
  font-size: 26px;
  padding-right: 10px;
}

.btn-blank-wrapper.stand-alone .btn-blank {
  color: #00cc99 !important;
}

.big-input {
  margin-top: 20px;
  display: block;
  width: 100%;
  height: 60px;
  font-size: 46px;
  font-weight: bold;
  background-color: transparent;
  font-family: "Architects Daughter", sans-serif;
  color: #929292;
  text-align: center;
  border: none !important;
}

.big-input:focus, .big-input:active, .big-input.active {
  box-shadow: none !important;
}

.big-input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #929292;
  opacity: 0.5;
  font-weight: normal;
}

.big-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #929292;
  opacity: 0.5;
  font-weight: normal;
}

.big-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #929292;
  opacity: 0.5;
  font-weight: normal;
}

.big-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #929292;
  opacity: 0.5;
  font-weight: normal;
}

.navbar {
  border-radius: 0;
  font-family: "Architects Daughter", sans-serif;
  padding: 6px 30px 3px;
  background-color: #fff;
  max-height: 55px;
  margin-bottom: 0;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.navbar .navbar-brand {
  padding: 0;
  max-height: 46px;
}

.navbar .navbar-brand img {
  height: 44px;
  width: auto;
}

.navbar .navbar-nav {
  padding: 8px 0 0;
}

.navbar .navbar-nav li {
  margin: 0 5px;
  padding-top: 5px;
}

.navbar .navbar-nav li a {
  padding: 0;
  font-size: 14px;
  color: #00cc99;
  font-weight: 700;
}

.navbar .navbar-nav li a:hover, .navbar .navbar-nav li a:focus {
  background-color: transparent;
}

.navbar .navbar-nav li.dropdown span {
  font-size: 18px;
}

.navbar .navbar-nav .nav-link a {
  padding: 0;
  font-size: 14px;
  color: #00cc99;
  font-weight: 700;
}

.navbar .navbar-nav .nav-link a:hover, .navbar .navbar-nav .nav-link a:focus {
  color: #00cc99;
  background-color: transparent;
  border-bottom: 2px solid #00cc99;
}

.navbar .navbar-nav .nav-link.no-border a:hover,
.navbar .navbar-nav .nav-link.no-border a:focus {
  border: none !important;
}

.navbar .navbar-nav .nav-button {
  padding: 0;
}

.navbar .dropdown-menu {
  min-width: 0;
  padding: 10px 12px 6px;
}

.navbar .dropdown-menu li {
  margin-bottom: 4px;
}

.navbar .btn-navbar {
  padding: 4px 8px;
  background-color: #00cc99;
  border: 1px solid #00cc99;
  color: #fff;
  font-weight: 600;
  width: 100%;
}

.navbar .btn-navbar:hover, .navbar .btn-navbar:active {
  background-color: #009999;
  border-color: #009999;
  color: #fff;
}

.navbar .btn-navbar-sec {
  background-color: #fff;
  border: 1px solid #00cc99;
  color: #00cc99;
  font-weight: 600;
}

.navbar .dropdown {
  height: 40px;
}

.navbar .locale-dropdown:hover, .navbar .locale-dropdown:focus {
  border: none !important;
}

.navbar .dropdown-menu {
  min-width: 0;
  width: 250px;
  padding: 8px 16px;
}

.navbar .dropdown-menu:after {
  display: block;
  clear: both;
  content: "";
}

.navbar .dropdown-menu .locale-choice {
  width: 25%;
  text-align: center;
  float: left;
  margin: 0 auto;
  padding: 0;
}

.navbar .dropdown-menu .locale-choice i {
  font-size: 30px;
}

.navbar .dropdown-menu .locale-choice a:hover, .navbar .dropdown-menu .locale-choice a:focus {
  border: none;
}

.navbar .separator {
  margin-top: 2px !important;
  color: #00cc99;
}

/********************************
********  NAVBAR RESPONSIVE  ****
*********************************/
@media (max-width: 992px) {
  .navbar {
    max-height: none;
  }
  .navbar-nav > .nav-link > a:hover,
  .navbar-nav > .nav-link > a:focus {
    border-bottom: none;
  }
  .navbar-nav li {
    margin: 5px 0 !important;
  }
  .btn-navbar {
    width: 100%;
  }
  .nav-link {
    text-align: center;
  }
  .navbar-brand {
    padding: 5px;
  }
  .separator {
    display: none;
  }
}

.nav-img {
  margin-top: -10px;
  height: 36px;
  width: auto;
  border-radius: 50%;
}

/* PROCESS BAR
=================================================================== */
.process-bar {
  color: #fff;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  overflow: hidden;
  font-weight: 100;
  font-size: 1.5vw;
  font-family: "Architects Daughter", cursive;
  background-color: #cc0066;
}

.process-bar div {
  color: #fff;
}

.process-bar .progress {
  margin-top: 15px;
}

#btn-points {
  cursor: pointer;
}

#btn-points a,
#btn-points a:focus,
#btn-points a:visited {
  color: #fff;
}

/* COURSE INDEX
=================================================================== */
.course-badge:hover, .course-badge:focus {
  outline: none;
  -webkit-filter: drop-shadow(0 0 25px #fff);
  filter: url(#drop-shadow);
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  cursor: pointer;
}

.course-index {
  padding: 0;
  background: #131056 url("/assets/img/instructions/space-world-new.png") no-repeat top right;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}

.course-index .world-title {
  position: absolute;
  top: 4%;
  color: #fff;
  left: calc(50% - 100px);
}

.course-index .course-badge-index {
  position: absolute;
  bottom: 45%;
  width: 16%;
  height: auto;
}

.course-index .course-badge-index[data-course='1'] {
  left: 20%;
}

.course-index .course-badge-index[data-course='2'] {
  left: 43%;
}

.course-index .course-badge-index[data-course='3'] {
  left: 70%;
}

.hidden-popover {
  display: none;
}

.course-popover {
  padding: 15px;
  max-width: 100%;
  width: 600px;
  height: 300px;
  text-align: left;
  background-color: #fff;
  position: relative;
}

.course-popover .course-title {
  margin-top: 0;
  color: #009999;
}

.course-popover .course-data {
  font-weight: bold;
  text-align: right;
  color: #929292;
}

.course-popover .course-description {
  margin-top: 15px;
}

.course-popover .course-preview {
  height: 100%;
  width: 100%;
}

.course-badge-buttons {
  position: absolute;
  left: 15px;
  bottom: 0;
}

/* PROJECT INDEX
=================================================================== */
.project-index-filter {
  padding: 30px 6% 0;
}

.project-index {
  padding: 30px 6%;
}

/* Opens INDEX
=================================================================== */
.open-index-block {
  padding: 50px;
}

.open-index-block:nth-child(even) {
  background-color: #ebebeb;
}

.open-index-block-title {
  text-align: center;
  color: #00cc99;
}

.open-title {
  text-align: center;
  color: #929292;
  font-family: "Architects Daughter", sans-serif;
}

.open-btn {
  display: block;
  margin: 15px auto 0;
  background-color: #fff;
  color: #00cc99;
  font-family: "Architects Daughter", sans-serif;
  text-transform: uppercase;
}

/* SKELETON
=================================================================== */
.course-page {
  position: relative;
}

.course-page .btn-blank-wrapper {
  position: absolute;
  bottom: 0;
  height: 52px;
}

.course-page .btn-blank-wrapper .btn-blank {
  padding: 2px 15px 6px;
}

/* INSTRUCTIONS
=================================================================== */
.instructions-wrapper {
  background-color: #fff;
  font-size: 110%;
  overflow: auto;
  height: 100%;
  padding: 0;
  border-right: 1px solid #ddd;
  box-shadow: 6px 15px 6px 0 #c4c4c4;
}

.instructions {
  padding: 20px 20px 250px;
  position: relative;
  min-height: 100%;
}

.instructions .alert a {
  font-weight: bold;
  color: #31708f;
  text-decoration: underline;
}

.instructions .alert a:hover, .instructions .alert a:visited, .instructions .alert a:focus, .instructions .alert a:active {
  color: #31708f;
}

.instructions h4 {
  background-color: #009999;
  color: #fff;
  text-align: center;
  padding: 5px;
}

.instructions img {
  max-width: 100%;
  margin: 10px 0;
}

.instructions .action {
  background-color: rgba(0, 153, 153, 0.1);
  padding: 20px 20px 5px;
  margin-left: -20px;
  width: 100%;
  border-top: 1px solid rgba(0, 153, 153, 0.8);
  position: absolute;
  bottom: 52px;
}

.instructions .action ol {
  list-style-type: lower-alpha;
}

.instructions .action ul {
  list-style-type: disc !important;
}

.instructions .action:before {
  content: "\f044";
  font-family: FontAwesome, sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin: -40px auto 15px;
  width: 60px;
  display: block;
  text-align: center;
  background-color: #fff;
  color: #009999;
  border: 1px solid rgba(0, 153, 153, 0.8);
  border-radius: 5px;
  padding: 5px 0 3px;
}

.instructions .action .CodeMirror {
  background-color: rgba(0, 153, 153, 1e-08);
}

.instructions-title {
  color: #009999;
  font-size: large;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 0 20px 4px 0;
  border-bottom: 2px solid #009999;
  text-align: left;
}

.instructions-btn {
  background-color: rgba(0, 153, 153, 0.1);
  color: #009999;
  margin-left: -20px;
  margin-right: -20px;
  width: 100%;
  text-align: center;
}

.instructions-btn .btn-blank {
  float: none;
}

.instructions-open .instructions-btn {
  background-color: transparent;
}

/* INSTRUCTIONS OPEN
=================================================================== */
.instructions-content-open {
  height: 100%;
  border-radius: 5px;
}

.instructions .instruction-heading-secondary {
  background-color: #00cc99;
}

.instructions .instruction-heading-tertiary {
  background-color: #cc0066;
}

.instructions .instructions-instruction {
  position: relative;
}

/* EDITORS AND OUTPUT
=================================================================== */
.course-page .editor-wrapper {
  padding-right: 0;
  padding-left: 25px;
  padding-top: 43px;
  padding-bottom: 52px;
  position: relative;
}

.editor-wrapper .CodeMirror {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.editor-wrapper .CodeMirror .line-error {
  background-color: rgba(255, 80, 80, 0.8);
}

.editor-wrapper .CodeMirror .line-highlight {
  background-color: rgba(255, 80, 80, 0.3);
}

.editor-tabs {
  height: 46px;
  padding-top: 12px;
  position: absolute;
  top: 0;
}

.editor-tabs:after {
  display: block;
  clear: both;
  content: "";
}

.editor-tabs .code-tab {
  height: 100%;
  width: 150px;
  margin-left: 50px;
  border-radius: 5px 5px 0 0;
  padding: 4px;
  display: block;
  background: #00cc99;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.btn-blank {
  color: #00cc99;
}

/* OUTPUT
=================================================================== */
.output-wrapper {
  padding: 43px 0 52px;
  position: relative;
}

.output {
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

/* CANVAS
=================================================================== */
.canvas-outer {
  background-color: #181818;
  border-radius: 10px;
  padding: 25px 25px 10px 25px;
  height: calc(100% + 45px);
  margin-top: -45px;
  -webkit-transform: rotate(3deg);
  transform: rotate(3deg);
  -webkit-transform-style: preserve-3d;
  box-shadow: 3px 6px 10px 0 #c4c4c4;
  -webkit-transition: box-shadow 1s;
  transition: box-shadow 1s;
}

.canvas-outer:hover {
  box-shadow: 6px 12px 20px 0 #ababab;
  -webkit-transition: box-shadow 1s;
  transition: box-shadow 1s;
}

.canvas-outer .canvas {
  background-color: #fff;
  height: calc(100% - 30px);
  overflow: hidden;
}

.canvas-outer .canvas-button {
  background-color: #181818;
  height: 30px;
}

.canvas-outer .canvas-button .canvas-tablet-button {
  width: 40px;
  height: 25px;
  border-radius: 25px;
  background-color: #292929;
  display: block;
  margin: 5px auto 0;
  color: #fff;
  font-size: large;
  font-weight: bold;
  text-align: center;
}

.canvas-outer #canvas {
  position: relative;
}

.canvas-outer #processing-canvas {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

/* OUTPUT BUTTONS
=================================================================== */
#btn-submit {
  color: #cc0066;
  border-radius: 5px 0 0 5px;
}

#btn-previous {
  color: #00cc99;
}

#btn-next, #btn-finish, #btn-publish {
  margin-left: 0;
  color: #00cc99;
}

#btn-publish, #btn-finish {
  display: none;
}

/* PROJECT TILE
=================================================================== */
.project-tile {
  box-shadow: 0 0 2px #3b3b3b;
  background-color: #fff;
  position: relative;
  margin-bottom: 40px;
}

.project-tile .course-badge-img {
  position: absolute;
  top: -10px;
  left: -10px;
  height: 80px;
  width: auto;
  z-index: 999;
  outline: none;
  -webkit-filter: drop-shadow(0 0 5px #8f8f8f);
  filter: url(#drop-shadow);
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#8f8f8f')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#8f8f8f')";
}

.project-tile .project-img-wrapper {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.project-tile .project-img {
  width: 100%;
  position: absolute;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
  margin: auto;
  height: auto;
}

.project-tile .project-info {
  padding: 15px;
}

.project-tile .project-sub {
  width: 100%;
}

.project-tile .project-sub:after {
  display: block;
  clear: both;
  content: "";
}

.project-tile .project-sub .project-author {
  float: left;
  font-size: smaller;
}

.project-tile .project-sub .project-stats {
  float: right;
}

.project-tile .project-title {
  margin: 5px 0 0;
  text-align: left;
}

.project-tile .project-tags {
  text-align: right;
  font-weight: bold;
  font-size: 14px;
}

/* PROJECT PAGE
=================================================================== */
.project-page #code-wrapper {
  margin-bottom: -6px;
}

.project-page .editor-wrapper {
  padding: 43px 0 52px;
}

.project-sidebar-wrapper {
  position: relative;
  padding-top: 30px;
}

.project-sidebar-wrapper .project-name {
  border-bottom: 2px solid #009999;
  padding-left: 85px;
  color: #009999;
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 12px;
  position: relative;
}

.project-sidebar-wrapper .project-author {
  padding-left: 85px;
  font-size: smaller;
}

.project-sidebar-wrapper .course-badge-project {
  position: absolute;
  left: -5px;
  bottom: -37px;
  width: 80px;
  height: 70px;
}

.project-sidebar-wrapper .btn-blank-wrapper {
  font-size: 16px;
  float: right;
}

.project-sidebar-wrapper .btn-blank-wrapper .btn-blank {
  color: #009999;
}

.project-sidebar-wrapper .btn-blank-wrapper .btn-delete {
  color: #929292;
}

.project-sidebar-wrapper .project-img {
  height: 200px;
}

.project-sidebar-wrapper .project-ad {
  text-align: center;
}

.highscore-title-wrapper {
  position: relative;
}

.highscore-title {
  position: absolute;
  bottom: 10px;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
}

.highscore-img {
  width: 100%;
  height: auto;
}

.highscore {
  padding-left: 30px;
  font-size: 14px;
}

.scores-wrapper {
  padding: 15px 30px 15px 10px;
}

.highscore-score {
  padding-left: 30px;
  float: right;
}

.project-level {
  height: calc(100vh - 100px);
}

.static-canvas-wrapper .canvas-outer {
  margin: 10px auto 0;
  height: calc(100% - 30px);
}

.static-canvas-wrapper .canvas {
  min-height: 400px;
  height: calc(100% - 30px);
  background-color: #fff;
}

.profile-page {
  padding: 30px 0;
}

.profile-page .form-input {
  padding: 0 15px;
}

.profile-page .alert {
  margin: 0 15px 20px;
  width: calc(100% - 30px);
}

.profile-pic-wrapper {
  position: relative;
  width: 18vw;
  height: 18vw;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
}

.profile-pic-wrapper.self:hover .profile-pic-hover {
  display: -webkit-box;
  display: flex;
}

.profile-pic {
  position: absolute;
  width: 100%;
  height: 100%;
}

.profile-pic img {
  border-radius: 50%;
}

.profile-pic-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* For IE8 and earlier */
  color: #454545;
  border-radius: 50%;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  display: none;
}

.profile-pic-hover-inner {
  text-align: center;
  margin: auto;
  display: block;
}

.profile-username {
  text-align: center;
  color: #9fafc9;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  font-size: xx-large;
}

.profile-btns {
  padding: 15px 15px 0;
}

.profile-edit-form-trigger {
  float: left;
}

.profile-logout {
  float: right;
}

.profile-edit-form {
  display: none;
}

.profile-info {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.profile-pic-unit {
  height: calc(100vh - 55px);
  padding-top: 100px;
  position: relative;
}

/*

.pic-edit-unit {

  background-color: #a4a4a4;
  height: 100%;

  .canvas-username {
    text-align: center;
    margin-top: 13px;
    font-family: $font-primary;
    color: #fff;
  }

  .canvas {
    width: 22vw;
    height: 22vw;
    margin: 0 auto;
    display: block;
  }

  #processing-canvas {
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: 0 0 40px 5px #fff;
  }

  .editor-wrapper {
    height: 18vw;
    width: 100%;
    display: block;
  }

  .cm-s-youplusplus {

    font-size: 18px;
    border-top: 30px solid #f7f7f7;
    border-bottom: 30px solid #f7f7f7;
    border-radius: 20px;

    .CodeMirror-gutters {
      background-color: #fff;
    }

    .CodeMirror-linenumber {
      color: #00c600;
    }
  }

  .btn-blank-wrapper {
    display: block;
    float: right;
    padding-right: 10px;
    .btn-blank {
      color: #fff !important;
    }
  }
}

%bb-nav {
  i {
    position: absolute;
    top: 40%;
    color: #fff;
    font-size: 50px;
    z-index: 99;
    opacity: 0.9;
  }
}

/* Onboarding Stuff
========================================================================== */
/*
.bb-nav-prev {
  @extend %bb-nav;
  i {
    left: 25px;
  }
}

.bb-nav-next {
  @extend %bb-nav;
  i {
    right: 25px;
    transition: transform 0.5s ease-in-out;
    &.state-1 {
      transform: scale(1, 1);
    }
    &.state-2 {
      transform: scale(1.2, 1.2)
    }
  }
}

.onboarding-page {
  height: calc(100vh - 55px);
}

.onboarding-container {
  background-color: $lightblue;
  position: relative;
  height: 100%;
  color: #fff;

  .edit-hero {
    text-align: center;
    padding: 30px 0;

    .title {
      font-weight: bold;
      font-family: $font-secondary;
      font-size: 350%;
    }
  }

  .row.padded {
    padding: 0 10%;
  }

  .hero-container {
    height: 100%;
    position: relative;
  }

  .hero-img {
    width: calc(100vh - 300px);
    height: calc(100vh - 300px);
    position: absolute;
    left: calc(50% - (100vh - 300px) / 2);
    bottom: 50px;
    transition: transform 0.5s ease-in-out;

    &.state-1 {
      transform: scale(0.95, 0.95);
    }

    &.state-2 {
      transform: scale(1.05, 1.05);
    }
  }

  .click-here {
    position: absolute;
    right: calc(50% + (100vh - 300px) / 2 + 50px);
    bottom: calc(50px + (100vh - 300px) / 2 + 80px);
    font-family: $font-secondary;
    font-size: 24px;
    transform: rotate(-6deg);

    &.arrow {
      font-size: 100px;
      font-family: "Lucida Sans Unicode", sans-serif;
      transform: rotate(270deg);
      bottom: calc(50px + (100vh - 300px) / 2);
      right: calc(50% + (100vh - 300px) / 2 + 50px);
    }
  }

}

.onboarding-progress {
  font-size: 24px;
  margin: 0;
  color: #fff;
}

.onboarding-username {
  font-weight: bold;
}

.onboarding-register-pic {
  width: 22vw;
  height: 22vw;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 40px 5px #fff;
}

.onboarding-register-username {
  text-align: center;
  color: #fff;
}

*/
#processing-canvas {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

/* ADMIN DASHBOARD
=================================================================== */
.admin-home {
  text-align: center;
}

.admin-analytics {
  margin-top: 20px;
  text-align: left;
  font-size: 20px;
}

.admin-analytics i {
  color: #009999;
}

.admin-analytics img {
  padding-top: 100px;
  width: 100%;
  height: auto;
}

.admin-analytics table {
  width: 100%;
  margin-bottom: 50px;
  font-size: 16px;
}

.admin-analytics thead {
  font-weight: bold;
  font-family: "Cabin Sketch Bold", sans-serif;
  color: #009999;
  border-bottom: 1px solid #929292;
  margin-bottom: 10px;
}

.admin-analytics tbody tr:nth-child(odd) {
  background-color: #ebebeb;
}

.admin-analytics td {
  padding: 0 3px;
}

/* ADMIN EDIT
=================================================================== */
.edit-page .CodeMirror {
  height: 300px;
}

.dashboard {
  padding-top: 0;
  overflow: hidden;
  height: calc(100vh - 100px);
}

.dashboard-links {
  height: calc(100vh - 100px);
  border-right: 2px solid #ddd;
}

.dashboard-link {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 153, 153, 0.8);
  background-color: rgba(0, 153, 153, 0.1);
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 300;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  position: relative;
}

.dashboard-link.active {
  background-color: #009999;
  border-radius: 0;
  border: none;
}

.dashboard-link a {
  width: 100%;
  height: 100%;
  padding: 15px 30px;
  background-color: transparent !important;
  border: none !important;
  color: #009999 !important;
}

.dashboard-link.active a {
  color: #fff !important;
}

.dashboard-link.active a:after {
  content: "";
  position: absolute;
  right: -30px;
  top: 0;
  width: 0;
  height: 50px;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 30px solid #009999;
}

.dashboard-pane {
  height: calc(100vh - 100px);
  padding: 50px;
  overflow-y: scroll;
  font-size: 120%;
  text-align: left;
}

.dashboard-pane hr {
  width: 100%;
}

.dashboard-pane-top {
  height: 225px;
  font-family: "Architects Daughter", sans-serif;
  font-size: 24px;
  color: #00cc99;
  line-height: 30px;
}

.dashboard-pane-top:after {
  display: block;
  clear: both;
  content: "";
}

.dashboard-pane-top-text {
  padding: 30px;
}

.dashboard-pane-main {
  padding: 30px 0;
  text-align: center;
}

.table-overview {
  margin-bottom: 30px;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table-overview th {
  height: auto;
  color: #454545;
  border-bottom: 1px solid #454545;
  font-family: "Architects Daughter", sans-serif;
  text-align: center;
}

.table-overview th:first-child {
  text-align: left;
}

.table-overview th:last-child {
  text-align: right;
}

.table-overview td {
  width: 16.67%;
  padding: 5px;
  text-align: center;
}

.table-overview td:first-child {
  text-align: left;
}

.table-overview td:last-child {
  text-align: right;
}

.table-overview tr {
  padding: 2px 0;
  border-bottom: 1px solid #929292;
}

.table-overview tr:nth-child(even) {
  background-color: #eee;
}

.table-overview tr.active {
  background-color: #ccf5eb;
}

.table-overview tr.active:nth-child(even) {
  background-color: #d9f7f0;
}

.course-name {
  border-bottom: 2px solid #009999;
  padding-left: 140px;
  color: #009999;
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 12px;
  position: relative;
}

.course-badge {
  position: absolute;
  left: -10px;
  bottom: -66px;
  width: 140px;
  height: 125px;
}

.course-content {
  text-align: center;
  padding-top: 80px;
}

.course-content a {
  color: #cc0066;
}

.course-tile {
  margin: 55px 5px 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 70px 15px 15px;
  position: relative;
}

.course-tile .tile-img {
  position: absolute;
  top: -50px;
  height: 100px;
  width: auto;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.course-tile .btn {
  font-family: "Architects Daughter", sans-serif, "sans serif";
  margin-bottom: 15px;
}

/* ERROR PAGES
=================================================================== */
.error-page {
  padding: 50px;
}

.error-page h1 {
  margin-top: 200px;
}

.email {
  text-align: center;
}

.email h1 {
  color: #00cc99;
}

.email img {
  width: 400px;
  max-width: 100%;
  display: block;
  margin: 15px auto;
}

.email button {
  display: block;
  margin: 0 auto;
  font-size: x-large;
}

/* MODULES - Re-usable site elements.
========================================================================== */
.modal {
  border-radius: 20px;
  text-align: center;
}

.modal::-webkit-scrollbar {
  display: none;
}

.modal-dialog {
  width: 80vw;
  height: 85vh;
}

.modal-content {
  height: 100%;
}

.modal-title {
  color: #fff;
  text-align: center;
  background-color: #00cc99;
  padding: 10px 25px;
  height: 60px;
}

.modal-body {
  padding: 15px;
  height: calc(100% - 100px);
}

.modal-close {
  min-height: 40px;
}

.modal-close:after {
  display: block;
  clear: both;
  content: "";
}

.modal-close .close {
  margin: 10px 0;
  float: none;
}

.modal-small .modal-dialog {
  display: inline-block;
  height: auto;
  max-height: 85vh;
  width: auto;
  max-width: 80vw;
}

.modal-contact .modal-dialog {
  width: 600px;
  max-width: 95vw;
  display: inline-block;
  height: auto;
  max-height: 95vh;
  overflow-y: scroll;
}

.modal-contact .modal-body {
  text-align: left;
  padding: 15px 25px;
}

.modal-contact .form-group-nps {
  width: 300px;
  display: block;
  margin: 0 auto;
}

.modal-contact .radio-group {
  float: left;
  display: block;
  width: 10%;
  text-align: center;
}

.modal-contact .radio-group label {
  display: block;
  margin-bottom: 3px;
  color: #00cc99;
}

.modal-contact .textarea-img {
  position: relative;
  z-index: 0;
}

.modal-contact .textarea-img p span {
  width: 75%;
  display: block;
}

.modal-contact .textarea-img img {
  position: absolute;
  right: 20px;
  bottom: 50px;
  width: 200px;
  height: auto;
  z-index: -1;
}

.modal-contact .btn-form-submit {
  width: auto;
  float: right;
}

.modal-quiz .modal-body {
  overflow-y: scroll;
}

.modal-quiz .row,
.modal-quiz .question-wrapper,
.modal-quiz .img-wrapper {
  height: 100%;
}

.modal-quiz .question-wrapper {
  padding: 20px;
}

.modal-quiz .question {
  font-size: medium;
  margin-bottom: 15px;
  margin-top: 0;
  color: #454545;
}

.modal-quiz .answer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  resize: vertical;
  overflow: auto;
  color: #fff;
  background-color: #00cc99;
  min-height: 30px;
  height: 14vh;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 2vh;
  padding: 10px;
  border-radius: 5px;
}

.modal-quiz .answer:hover {
  background-color: #009999;
}

.modal-quiz .answer.fault, .modal-quiz .answer.fault:hover {
  background-color: #beb8ba;
  cursor: auto;
}

.modal-quiz img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
}

.modal-quiz .feedback {
  padding: 15px;
  display: none;
}

.modal-quiz .feedback img {
  display: inline-block;
  width: 120px;
  height: auto;
}

.modal-quiz .feedback p {
  float: right;
  width: calc(100% - 120px);
  padding-left: 15px;
  font-size: large;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
}

.modal-quiz .feedback p.correct {
  color: #00cc99;
}

.modal-quiz .feedback p.false {
  color: #ff464b;
}

.modal-quiz-time .modal-dialog {
  position: relative;
}

.modal-quiz-time img {
  width: 60%;
  height: auto;
  margin: auto;
}

.modal-quiz-time .btn-quiz-time {
  font-size: 240%;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.modal-hint .modal-title {
  background-color: #009999;
}

.modal-hint .modal-body {
  padding: 0;
}

.modal-hint .modal-hint-elements {
  border-right: 1px solid rgba(0, 153, 153, 0.8);
  border-bottom: none;
  height: 100%;
  position: relative;
}

.modal-hint .modal-hint-elements-scrollable {
  height: calc(100% - 70px);
  overflow-y: scroll;
}

.modal-hint ul {
  border: none;
}

.modal-hint .modal-hint-element {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 153, 153, 0.8);
  background-color: rgba(0, 153, 153, 0.1);
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 300;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
}

.modal-hint .modal-hint-element.solution {
  font-size: 22px;
  background-color: #009999;
  border-top: 1px solid rgba(0, 153, 153, 0.8);
  border-bottom: none;
  border-radius: 0 0 0 5px;
  height: 70px;
  margin: -1px 0 0;
}

.modal-hint .modal-hint-element.solution span {
  color: #fff;
  line-height: 35px;
}

.modal-hint .modal-hint-element.solution.disabled {
  background-color: #eee;
}

.modal-hint .modal-hint-element .points-scored.negative {
  color: #ff464b;
}

.modal-hint .modal-hint-element a {
  width: 100%;
  height: 100%;
  padding: 15px 30px;
  background-color: transparent !important;
  border: none !important;
  color: #009999 !important;
}

.modal-hint .tab-pane {
  height: 100%;
  padding: 15px 60px;
  overflow-y: scroll;
  font-size: 120%;
  text-align: left;
}

.modal-hint .tab-pane img {
  margin: 15px 0 15px 30px;
  max-width: 60%;
}

.modal-hint .table-mistakes td {
  padding: 5px 0;
}

.modal-hint .table-mistakes td:first-child {
  padding-right: 30px;
}

.modal-instruction .modal-title {
  background-color: #00cc99;
}

.modal-instruction .modal-quiz-title {
  background-color: #00cc99;
}

.modal-instruction img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
}

.modal-instruction .instruction-custom {
  height: calc(100% - 92px);
  overflow: scroll;
  padding-top: 30px;
}

.modal-instruction .instruction-custom div:nth-child(1) {
  height: 100%;
}

.modal-instruction .instruction-custom div:nth-child(2) {
  height: 100%;
}

.modal-instruction .btn-modal-instructions {
  width: 200px;
  height: 50px;
  float: right;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  font-size: x-large;
}

.modal-instruction p, .modal-instruction li {
  font-size: large;
}

.modal-instruction-new .modal-body {
  height: 100%;
  padding: 0;
  position: relative;
}

.modal-instruction-new .close {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 99;
}

.modal-instruction-new .instructions-text {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  background: url(/assets/img/instructions/book.svg) no-repeat center center;
  background-size: 100% 100%;
  position: relative;
  z-index: 0;
}

.modal-instruction-new .instructions-custom-wrapper {
  text-align: left;
  position: absolute;
  left: 8%;
  top: 11.5%;
  width: 40.5%;
  height: 74%;
  overflow-y: auto;
}

.modal-instruction-new .instructions-custom {
  position: relative;
  padding: 50px 20px 15px;
  min-height: 100%;
  font-size: 110%;
}

.modal-instruction-new .instructions-custom h4 {
  text-align: center;
}

.modal-instruction-new .instructions-custom .border-bottom {
  position: absolute;
  bottom: 15px;
  border-bottom: 2px solid #009999;
  width: calc(100% - 40px);
}

.modal-instruction-new .instructions-custom .topic {
  color: #cc0066;
  font-size: 160%;
  text-align: left;
}

.modal-instruction-new .instructions-custom .chapter {
  margin: 0;
  font-size: 100%;
  font-weight: 100;
  position: absolute;
  padding: 0 15px;
  border-bottom: 2px solid #009999;
  border-right: 30px solid #009999;
  top: 15px;
  right: 0;
}

.modal-instruction-new .instructions-img {
  position: absolute;
  right: 4.5%;
  top: 10%;
  width: 32%;
  height: 50%;
  -webkit-transform: rotate(2.5deg);
  transform: rotate(2.5deg);
  -webkit-transform-style: preserve-3d;
  overflow: auto;
}

.modal-instruction-new .instructions-img img {
  max-height: 100%;
}

.modal-instruction-new .instructions-part {
  position: absolute;
  left: 20%;
  bottom: 7%;
  width: 7%;
  height: 5%;
  text-align: center;
  font-size: x-large;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  color: white;
}

.modal-instruction-new .btn-modal-instructions {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
  font-size: x-large;
  padding: 5px 20px;
}

.modal-project .project-preview {
  padding: 15px;
}

.modal-project .project-preview img {
  border: 1px solid #b2b2b2;
}

.modal-project label {
  text-align: right;
}

.modal-project .project-modal-title {
  text-align: left;
}

.modal-subscribe img {
  height: 350px;
}

.modal-subscribe .modal-body-half {
  width: 50%;
  float: left;
}

.modal-subscribe p {
  text-align: left;
  font-size: 1em;
  font-weight: normal;
  color: #2c2c2c;
  margin: 25px auto;
}

.modal-subscribe .thank-you {
  color: #cc0066;
  margin-top: 20px;
}

.modal-points .wrapper {
  z-index: 0;
  position: relative;
}

.modal-points .modal-title {
  background-color: #cc0066;
}

.modal-points .modal-body {
  padding: 30px;
}

.modal-points .background-img {
  position: absolute;
  right: 0;
  top: -130px;
  width: 150px;
  height: auto;
  z-index: -1;
}

.modal-points .fb-like {
  margin-top: 10px;
}

.modal-points .points-tile-small, .modal-points .points-tile-large {
  border: 1px solid #eee;
  margin: 5px -10px;
  position: relative;
  background-color: #f8f8f8;
}

.modal-points .points-tile-small span, .modal-points .points-tile-large span {
  font-weight: bold;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
}

.modal-points .negative.points-tile-small span, .modal-points .negative.points-tile-large span {
  color: #ff464b;
}

.modal-points .positive.points-tile-small span, .modal-points .positive.points-tile-large span {
  color: #00cc99;
}

.modal-points .points-tile-small .points-title, .modal-points .points-tile-large .points-title {
  color: #929292;
  position: absolute;
  width: 90%;
  margin-left: -45%;
  top: 15%;
  left: 50%;
}

.modal-points .points-tile-small .points-possible, .modal-points .points-tile-large .points-possible {
  position: absolute;
  right: 4px;
  top: 4px;
}

.modal-points .points-tile-small .points-gained, .modal-points .points-tile-large .points-gained {
  position: absolute;
  width: 90%;
  bottom: 4px;
  left: 50%;
  margin-left: -45%;
}

.modal-points .points-tile-small {
  height: 97px;
}

.modal-points .points-tile-small .points-title {
  font-size: medium;
}

.modal-points .points-tile-small span {
  font-size: smaller;
}

.modal-points .points-tile-large {
  height: 200px;
}

.modal-points .points-tile-large .points-title {
  font-size: x-large;
}

.modal-points .points-tile-large span {
  font-size: small;
}

/* BUTTONS
=================================================================== */
.btn:hover, .btn:focus, .btn:active, .btn.active {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary {
  background-color: #00cc99;
  border-color: #00cc99;
  color: #fff !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  background-color: #009999;
  border-color: #009999;
  color: #fff;
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: #00cc99;
  border-color: #00cc99;
  color: #fff !important;
}

.btn-primary:disabled:hover, .btn-primary:disabled:visited, .btn-primary:disabled:focus, .btn-primary:disabled:active, .btn-primary:disabled.active,
.btn-primary.disabled:hover,
.btn-primary.disabled:visited,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active {
  background-color: #00cc99 !important;
  border-color: #00cc99 !important;
  color: #fff;
}

.btn-secondary {
  background-color: #cc0066;
  border-color: #cc0066;
  color: #fff !important;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active {
  background-color: #fff;
  border-color: #cc0066;
  color: #cc0066 !important;
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  background-color: #fff;
  border-color: #cc0066;
  color: #cc0066 !important;
}

.btn-secondary:disabled:hover, .btn-secondary:disabled:visited, .btn-secondary:disabled:focus, .btn-secondary:disabled:active, .btn-secondary:disabled.active,
.btn-secondary.disabled:hover,
.btn-secondary.disabled:visited,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:active,
.btn-secondary.disabled.active {
  background-color: #cc0066;
  border-color: #cc0066;
  color: #fff !important;
}

.btn-nav {
  padding: 4px 8px;
  background-color: #00cc99;
  border: 1px solid #00cc99;
  color: #fff;
  font-weight: 600;
}

.btn-nav:hover, .btn-nav:active {
  background-color: #009999;
  border-color: #009999;
  color: #fff;
}

.btn-nav-sec {
  background-color: #fff;
  border: 1px solid #00cc99;
  color: #00cc99;
  font-weight: 600;
}

.btn-funky {
  font-size: large;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  padding: 4px 15px;
}

.btn-blank-wrapper {
  padding: 8px 0;
  border-radius: 0;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  font-size: 22px;
}

.btn-blank-wrapper form {
  float: left;
}

.btn-blank-wrapper:after {
  display: block;
  clear: both;
  content: "";
}

.btn-blank {
  float: left;
  cursor: pointer;
  display: inline-block;
  width: auto;
  font: inherit;
  padding: 5px 8px;
  height: 100%;
  -webkit-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
  border: none;
  background-color: transparent;
  border-left: 1px solid #ddd;
  border-radius: 0;
}

.btn-blank:first-child {
  border-left: none;
}

.btn-blank:hover {
  color: #929292 !important;
  -webkit-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.btn-blank:disabled {
  color: #ddd !important;
}

.js-btn {
  cursor: pointer;
}

.btn-hidden {
  display: none;
}

/* LINKS
=================================================================== */
a {
  color: #009999;
  text-decoration: none;
  outline: none;
}

a:hover, a:visited, a:focus, a:active {
  text-decoration: none;
  color: #00cc99;
  outline: none !important;
}

/* POPOVERS
=================================================================== */
.points-popover {
  color: #929292;
}

.points-popover .popover-title {
  color: #000;
  text-align: center;
}

.points-popover .points-scored {
  font-size: large;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
}

.points-popover .points-scored.positive {
  color: #00cc99;
}

.points-popover .points-scored.negative {
  color: #ff464b;
}

.points-popover i {
  color: #ff464b;
  font-weight: bold;
  font-style: normal;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
}

.points-popover i.emph {
  color: #00cc99;
}

.flash-popover {
  color: #929292;
  display: block;
  position: absolute;
  top: 25px;
  right: -250px;
  width: 250px;
  z-index: 999;
  -webkit-transition: right 0.6s;
  transition: right 0.6s;
}

.flash-popover.visible {
  right: -100px;
}

.flash-popover img {
  height: 300px;
  width: auto;
}

.flash-popover i {
  color: #ff464b;
  font-weight: bold;
  font-style: normal;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
}

.flash-popover i.emph {
  color: #00cc99;
}

/* PAGINATION
=================================================================== */
.pagination-wrapper {
  text-align: center;
}

ul.pagination {
  display: inline-block;
}

ul.pagination a {
  color: #009999 !important;
}

ul.pagination li.active {
  border-color: #009999 !important;
}

ul.pagination li.active span {
  border-color: #009999 !important;
  background-color: #009999 !important;
}

/* STUFF
=================================================================== */
.small-show {
  display: none !important;
}

@media (max-width: 991px) {
  .small-show {
    display: block !important;
  }
  .small-hide {
    display: none !important;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

i.icon-left {
  margin-right: 7px;
}

i.icon-right {
  margin-left: 5px;
}

/* CODE MIRROR
=================================================================== */
.cm-s-youplusplus {
  height: 100%;
  font-size: 16px;
  font-family: "Source Code Pro", monospace;
  color: black;
}

.cm-s-youplusplus span.cm-comment {
  color: #c1c1c1;
}

.cm-s-youplusplus span.cm-keyword {
  line-height: 1em;
  font-weight: bold;
  color: #3a3;
}

.cm-s-youplusplus span.cm-string {
  color: #ffa918;
  font-weight: bold;
}

.cm-s-youplusplus span.cm-builtin {
  line-height: 1em;
  font-weight: bold;
  color: #077;
}

.cm-s-youplusplus span.cm-special {
  line-height: 1em;
  font-weight: bold;
  color: #0aa;
}

.cm-s-youplusplus span.cm-variable {
  color: black;
}

.cm-s-youplusplus span.cm-number, .cm-s-youplusplus span.cm-atom {
  color: #00aeef;
}

.cm-s-youplusplus span.cm-meta {
  color: #555;
}

.cm-s-youplusplus span.cm-link {
  color: #3a3;
}

.cm-s-youplusplus .CodeMirror-linenumber {
  color: #999;
}

.cm-s-youplusplus .CodeMirror-activeline-background {
  background: #e8f2ff;
}

.cm-s-youplusplus .CodeMirror-matchingbracket {
  outline: 1px solid grey;
  color: black !important;
}

.modal .cm-s-youplusplus,
.instructions .cm-s-youplusplus {
  height: auto;
  background: transparent;
}

/* FLAGS
=================================================================== */
/* https://github.com/lipis/flag-icon-css/blob/master/css/flag-icon.css  */
.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}

.flag-icon:before {
  content: "\00a0";
}

.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-en {
  background-image: url(../img/flags/gb.svg);
}

.flag-icon-en.flag-icon-squared {
  background-image: url(../img/flags/gb-square.svg);
}

.flag-icon-nl {
  background-image: url(../img/flags/nl.svg);
}

.flag-icon-nl.flag-icon-squared {
  background-image: url(../img/flags/nl-square.svg);
}

.flag-icon-sv {
  background-image: url(../img/flags/se.svg);
}

.flag-icon-sv.flag-icon-squared {
  background-image: url(../img/flags/se-square.svg);
}

.flag-icon-de {
  background-image: url(../img/flags/de.svg);
}

.flag-icon-de.flag-icon-squared {
  background-image: url(../img/flags/de-square.svg);
}

/* GRAPHS
=================================================================== */
.full-canvas {
  width: 100%;
}

.graph {
  margin: 15px 0;
}

.graph .graph-inner {
  background-color: #fcfcfc;
  padding: 30px 30px 10px;
  border: 1px solid #757575;
  margin-bottom: 15px;
}

.graph .graph-title {
  margin: 0 0 15px;
}

.graph .graph-legend ul {
  list-style: none;
}

.graph .graph-description {
  text-align: center;
}

/* OTHER
=================================================================== */
.language-alert {
  text-align: center;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.positive {
  color: #00cc99;
  font-weight: bold;
}

.tour-popover {
  max-width: 100vw;
  width: 400px;
}

.tour-popover .popover-title-container {
  display: -webkit-box;
  display: flex;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.tour-popover .popover-title {
  -webkit-box-flex: 1;
  flex: 1;
  font-size: larger;
}

.tour-popover .popover-close {
  flex-basis: 25px;
  padding: 6px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.tour-popover .popover-content {
  height: 180px;
  padding: 15px;
  position: relative;
  display: -webkit-box;
  display: flex;
}

.tour-popover .popover-content .tour-text {
  padding-left: 15px;
  -webkit-box-flex: 1;
  flex: 1;
}

.tour-popover .popover-content .tour-text i {
  font-weight: bold;
  font-style: normal;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
  color: #ff464b;
}

.tour-popover .popover-content .tour-text i.emph {
  color: #00cc99;
}

.tour-popover .popover-content .tour-text i.string {
  color: #ffa918;
  font-family: "Source Code Pro", monospace;
}

.tour-popover .popover-content img {
  height: 150px;
  width: auto;
  max-width: 100%;
}

.popover-navigation {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.popover-navigation button {
  float: right;
}

.popover-navigation button[data-role='prev'] {
  margin-left: 10px;
  margin-right: 0;
  border-right: 1px solid #fff;
  border-radius: 5px 0 0 5px;
}

.popover-navigation button[data-role='next'],
.popover-navigation button[data-role='end'] {
  margin-left: 0;
  border-radius: 0 5px 5px 0;
}

/* FORMS
=================================================================== */
.form-wrapper {
  margin-top: 80px;
}

.form-wrapper .alert {
  padding: 5px;
  margin-bottom: 5px;
}

.form-wrapper .panel-body {
  padding: 15px 30px;
}

@media (min-width: 768px) {
  .form-wrapper {
    margin-top: 80px;
  }
}

.form-input {
  padding: 0;
}

.panel-heading {
  display: block;
  clear: both;
  content: "";
}

.panel-title {
  margin: 0;
  display: inline-block;
  font-size: large !important;
  color: #009999 !important;
  font-family: "Cabin Sketch Bold", Helvetica, sans-serif;
}

.panel-heading .panel-refer {
  font-size: medium;
  color: #333;
}

.date-group select:nth-child(1) {
  float: left;
  width: 25%;
  margin-right: 5%;
}

.date-group select:nth-child(2) {
  float: left;
  width: 35%;
  margin-right: 5%;
}

.date-group select:nth-child(3) {
  float: left;
  width: 30%;
}

/* FORM ELEMENTS
=================================================================== */
textarea:focus, input:focus, input[type]:focus, select:focus, .uneditable-input:focus, .div-textarea:focus {
  border: none !important;
  box-shadow: 0 0 2px #3b3b3b;
  outline: 0 none !important;
}

.form-group {
  margin-bottom: 10px;
}

.form-group:after {
  display: block;
  clear: both;
  content: "";
}

form button[type='submit'] {
  width: 100%;
}

/* bookblock.css
=================================================================== */
.bb-bookblock {
  margin: 0 auto;
  position: relative;
  z-index: 100;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  -webkit-perspective: 2000px;
  perspective: 2000px;
}

.bb-nav {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 99999;
}

.bb-page {
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.bb-vertical .bb-page {
  width: 50%;
  height: 100%;
  left: 50%;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.bb-horizontal .bb-page {
  width: 100%;
  height: 50%;
  top: 50%;
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

.bb-page > div,
.bb-outer,
.bb-content,
.bb-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bb-vertical .bb-content {
  width: 200%;
}

.bb-horizontal .bb-content {
  height: 200%;
}

.bb-page > div {
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.bb-vertical .bb-back {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.bb-horizontal .bb-back {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.bb-outer {
  width: 100%;
  overflow: hidden;
  z-index: 999;
}

.bb-overlay,
.bb-flipoverlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.bb-flipoverlay {
  background-color: rgba(0, 0, 0, 0.2);
}

.bb-bookblock.bb-vertical > div.bb-page:first-child,
.bb-bookblock.bb-vertical > div.bb-page:first-child .bb-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.bb-bookblock.bb-horizontal > div.bb-page:first-child,
.bb-bookblock.bb-horizontal > div.bb-page:first-child .bb-back {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

/* Content display */
.bb-content {
  background: #fff;
}

.bb-vertical .bb-front .bb-content {
  left: -100%;
}

.bb-horizontal .bb-front .bb-content {
  top: -100%;
}

/* Flipping classes */
.bb-vertical .bb-flip-next,
.bb-vertical .bb-flip-initial {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.bb-vertical .bb-flip-prev {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.bb-horizontal .bb-flip-next,
.bb-horizontal .bb-flip-initial {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.bb-horizontal .bb-flip-prev {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.bb-vertical .bb-flip-next-end {
  -webkit-transform: rotateY(-15deg);
  transform: rotateY(-15deg);
}

.bb-vertical .bb-flip-prev-end {
  -webkit-transform: rotateY(-165deg);
  transform: rotateY(-165deg);
}

.bb-horizontal .bb-flip-next-end {
  -webkit-transform: rotateX(15deg);
  transform: rotateX(15deg);
}

.bb-horizontal .bb-flip-prev-end {
  -webkit-transform: rotateX(165deg);
  transform: rotateX(165deg);
}

.bb-item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

/* No JS */
.no-js .bb-bookblock,
.no-js ul.bb-custom-grid li {
  width: auto;
  height: auto;
}

.no-js .bb-item {
  display: block;
  position: relative;
}

/* demo4.css
=================================================================== */
.bb-custom-side {
  width: 50%;
  float: left;
  height: 100%;
  overflow: hidden;
  background: #fff;
  /* Centering with flexbox */
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.bb-custom-firstpage h1 {
  font-size: 2.625em;
  line-height: 1.3;
  margin: 0;
  font-weight: 300;
  background: #fff;
}

.bb-custom-firstpage h1 span {
  display: block;
  font-size: 60%;
  opacity: 0.3;
  padding: 0 0 0.6em 0.1em;
}

.bb-custom-firstpage {
  text-align: center;
  padding-top: 15%;
  width: 50%;
  float: left;
  height: 100%;
}

.bb-custom-side p {
  padding: 8%;
  font-size: 1.8em;
  font-weight: 300;
}

.bb-custom-wrapper h3 {
  font-size: 1.4em;
  font-weight: 300;
  margin: 0.4em 0 1em;
}

.bb-custom-wrapper > nav {
  width: 100%;
  height: 40px;
  margin: 1em auto 0;
  position: fixed;
  bottom: 20px;
  z-index: 1000;
  text-align: center;
}

.bb-custom-wrapper > nav a {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 2px;
  background: #1baede;
  color: #fff;
  font-size: 0;
  margin: 2px;
}

.bb-custom-wrapper > nav a:hover {
  opacity: 0.6;
}

.bb-custom-icon:before {
  font-family: 'arrows';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 30px;
  line-height: 40px;
  display: block;
  -webkit-font-smoothing: antialiased;
}

.bb-custom-icon-first:before,
.bb-custom-icon-last:before {
  content: "\e002";
}

.bb-custom-icon-arrow-left:before,
.bb-custom-icon-arrow-right:before {
  content: "\e003";
}

.bb-custom-icon-arrow-left:before,
.bb-custom-icon-first:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* No JS */
.no-js .bb-custom-wrapper {
  height: auto;
}

.no-js .bb-custom-content {
  height: 470px;
}

@media screen and (max-width: 61.75em) {
  .bb-custom-side {
    font-size: 70%;
  }
}

@media screen and (max-width: 33em) {
  .bb-custom-side {
    font-size: 60%;
  }
}

/*# sourceMappingURL=youplusplus.css.map */
